import {convertCheckoutCurrencyFromGBP} from "./convertCheckoutCurrencyFromGBP";

function loadCheckoutPrices() {
    var checkout_prices = $('.checkout-price');

    checkout_prices.each(function() {
        var gbp_price = $(this).attr('data-price'),
            currency = $('.checkout-total .checkout-price').attr('data-currency'),
            converted_price = convertCheckoutCurrencyFromGBP(gbp_price),
            formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                signDisplay: 'auto'
            });

        $(this).text(formatter.format(converted_price));
    })
}

export {loadCheckoutPrices}