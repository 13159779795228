// Load live metal prices
function setDefaultCurrency(currency) {

    var url = '/data/update-default-currency';

    $.ajax({
        url: url,
        type: 'get',
        data: {
            'currency' : currency
        },
        dataType: 'json'
    });

    return false;
}

export {setDefaultCurrency}