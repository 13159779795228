// Show a popup
function showPopup(selector) {
    if ($(selector).length) {
        if ($(selector).attr('id') === 'popup-login-register') {
            $(selector).find('#register-form-recaptcha2').attr('disabled', 'disabled');
            $(selector).find('.field-register-form-recaptcha2').hide();
        }
        $(selector).addClass('active');
        $('body').addClass('no-scroll');
    }
}

export {showPopup}