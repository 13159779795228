var SliderGroup = function() {
    
    var TAB_SELECTOR = ".select-tab";
    var FLEXSLIDER_SELECTOR = '.flexslider';
    var NAV_SELECTOR = '.nav-bar';
    var FOOTER_SELECTOR = 'footer';
    var CONTROL_NAV_CLASS = 'control-nav';
    var NO_AUTO_SLIDE = 'auto-slide-false';
    var FLEX_HEIGHT_CLASS = 'flex-height';
    var PAGINATION_CLASS = 'pagination-dots';
    var NO_LOOP_CLASS = 'remove-looping';
    var REVERSE_CLASS = 'reverse';
    var TOUCH_OFF_CLASS = 'touch-off';
    var FULL_HEIGHT_CLASS= "full-height";
    var IS_NAV_SLIDER_CLASS= "isNav";
    var MULTI_SHOW_CLASS= "multiShow";
    var IGNORE_CLASS = "ignore";
    var CONTROLLED_CLASS= "controlled";

    var slider;

    var defaultOptions = {
        animation: "slide",
        slideshow: true,
        controlNav: false,
        directionNav: false,
        reverse: false,
        smoothHeight: false,
        animationSpeed: 300,
        video: true,
        direction: 'horizontal',
        animationLoop: true,
        useCSS:false,
        touch:true,
        prevText: "",
        nextText: ""
    };

    this.init = function() {
        if ($('#product-slider-main').length == 0) {
            var count = 1;
            $(FLEXSLIDER_SELECTOR).each(function () {
                if (!$(this).hasClass(IGNORE_CLASS)) {
                    var options = defaultOptions;
                    slider = $(this);
                    slider.flexslider(updateOptions(options));
                    handleResize();
                    handleClick();
                    updateHeight();
                    count = count + 1;
                    if ($(this).find('video').length > 0) {
                        playVideos($(this).find('video'));
                    }
                }
            });

            $(document).on('click', '.control-nav li', function () {
                var tab = $(this).index();
                var parent = $(this).closest('.flexslider');
                $(parent).flexslider(tab);
            });
        }
    };

    var playVideos = function(videos) {
        videos.each(function(){
            $(this).get(0).play();
        })
    }
    var updateHeight = function() {
        if(slider.hasClass(FULL_HEIGHT_CLASS)) {
            handleResize();
            setFullWindowHeight();
        }
    };
    var updateOptions = function(options) {
        if(slider.hasClass(CONTROLLED_CLASS)) {
            options.sync = slider.attr('data-sync');
        }
        if(slider.hasClass(IS_NAV_SLIDER_CLASS)) {
            options.asNavFor = slider.attr('data-nav');
        }
        if(slider.hasClass(MULTI_SHOW_CLASS)) {
            options.itemWidth = 135;
            options.itemMargin = 15;
        }
        if(slider.hasClass(CONTROL_NAV_CLASS)) {
            options.directionNav = true;
        }else{
            options.directionNav = false;
        }
        if(slider.hasClass(TOUCH_OFF_CLASS)){
            options.touch = false;
        }else{
            options.touch = true;
        }
        if(slider.hasClass(REVERSE_CLASS)) {
            options.reverse = true;
        }else{
            options.reverse = false;
        }
        if(slider.hasClass(FLEX_HEIGHT_CLASS)) {
            options.smoothHeight = true;
        }else{
            options.smoothHeight = false;
        }
        if(slider.hasClass(NO_AUTO_SLIDE)) {
            options.slideshow = false;
        }else{
            options.slideshow = true;
        }
        if(slider.hasClass(PAGINATION_CLASS)) {
            options.controlNav = true;
        }else{
            options.controlNav = false;
        }
        //if(slider.hasClass(VERTICAL_CLASS)) {
        //    options.direction = 'vertical';
        //}else{
        //    options.direction = 'horizontal';
        //}

        if(slider.hasClass(NO_LOOP_CLASS)) {
            options.animationLoop = false;
        }else{
            options.animationLoop = true;
        }
        return options;
    };
    var handleResize = function() {
        $(window).resize(setFullWindowHeight);

    };
    var getNavHeight = function() {
        return $(window).height()-$(NAV_SELECTOR).height()-$(FOOTER_SELECTOR).height()-$('.get-started-slider').height();
    };
    var getSlideHeight = function(){
        if($(window).width()<768){
            return 550;
        }
        if($(window).width()<992){
            return 800;
        }
        return getNavHeight();
    };
    var setFullWindowHeight = function() {
        $('.slide-inner').height(getSlideHeight());
    };
    var handleClick = function() {
        $(document).on('click', TAB_SELECTOR, function () {
            var parent = $(this).closest('.flex-wrapper');
            var slider = $(parent).find('.flexslider');
            $(slider).flexslider($(this).index());
        });
    };
};

if ($('.flexslider').length > 0) {
    var sg = new SliderGroup;
    sg.init();
}