function newsletter_signup_response(popup_contents) {
    $('#newsletter-form').trigger('reset');
    $('.newsletter-interests').html(popup_contents.result.interests);

    // google analytics tracking
    /*eslint-disable */
    if(window.ga) {
        ga('send', 'event', {
            eventCategory: '\'' + $.trim($(document).find("title").text()) + '\'',
            eventAction: 'click',
            eventLabel: 'News Letter Signup'
        });
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'newsletter_signup',
        'category': '\'' + $.trim($(document).find("title").text()) + '\'',
        'action': 'click',
        'label': 'Newsletter Signup',
    });
    /*eslint-enable */
}

export {newsletter_signup_response}