var AnimationScrollClass = function() {

    var ANIMATE_SELECTOR_CLASSS = 'animate-on-view';
    var ANIMATE_SELECTOR = '.'+ANIMATE_SELECTOR_CLASSS;
    var ANIMATE_CLASS = 'animated';


    this.init = function () {
        if($(ANIMATE_SELECTOR).length > 0){
            detectScroll();
        }
    };

    function detectScroll()
    {
        $(document).on('scroll',function(event){
            var $animatedElement = $(ANIMATE_SELECTOR);
            if($animatedElement.length > 0) {
                $animatedElement.each(function () {
                    if (isScrolledIntoView($(this))) {
                        addAnimation($(this));
                    }
                });
            }else{
                $(this).off(event);
            }
        });
    }

    function addAnimation(elem)
    {
        var $elem = elem;
        $elem.addClass(ANIMATE_CLASS + ' hidden');
        setTimeout(function(){
            $elem.removeClass(ANIMATE_SELECTOR_CLASSS);
            $elem.removeClass('hidden');
        },5);
    }

    function isScrolledIntoView(elem)
    {
        var $window = $(window),
            docViewTop = $window.scrollTop(),
            docViewBottom = docViewTop + $window.height() - 200,
            elemTop = elem.offset().top;
        return (docViewBottom > elemTop);
    }

};

setTimeout(function () {
    var tes = new AnimationScrollClass;
    tes.init();

}, 2000);
