// Load live metal prices
import {processPriceUpdate} from "./processPriceUpdate";
import {updateProductPrices} from "./updateProductPrices";

function roundValue(value)
{
    return parseFloat(value.replace(",","")).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getMetalPrices(manual, currency)
{
    var url = '/data/update-live-price-ticker',
        livePriceTicker = $('#js-live-price-ticker'),
        goldData = livePriceTicker.find('#js-gold-live-price'),
        goldCurrent = goldData.find('#js-gold-current-price'),
        // goldChange = goldData.find('#js-gold-price-change'),

        silverData = livePriceTicker.find('#js-silver-live-price'),
        silverCurrent = silverData.find('#js-silver-current-price'),
        // silverChange = silverData.find('#js-silver-price-change'),

        platinumData = livePriceTicker.find('#js-platinum-live-price'),
        platinumCurrent = platinumData.find('#js-platinum-current-price'),
        // platinumChange = platinumData.find('#js-platinum-price-change'),

        palladiumData = livePriceTicker.find('#js-palladium-live-price'),
        palladiumCurrent = palladiumData.find('#js-palladium-current-price');
        // palladiumChange = palladiumData.find('#js-palladium-price-change');

    $.ajax({
        url: url,
        type: 'get',
        data: {
            full : false,
            currency: currency
        },
        dataType: 'json',
        success: function(data) {

            updateProductPrices(data, currency);
            if (data.hasOwnProperty('gold')) {
                processPriceUpdate(manual, goldCurrent, roundValue(data.gold.current), " /\xa0oz");
                // processPriceUpdate(manual, goldChange,data.gold.change);

                // goldChange.removeClass('positive negative').addClass(data.gold.changeType);
            }
            if (data.hasOwnProperty('silver')) {
                processPriceUpdate(manual, silverCurrent, roundValue(data.silver.current), " /\xa0oz");
                // processPriceUpdate(manual, silverChange, data.silver.change);
                //
                // silverChange.removeClass('positive negative').addClass(data.silver.changeType);
            }
            if (data.hasOwnProperty('platinum')) {
                processPriceUpdate(manual, platinumCurrent, roundValue(data.platinum.current), " /\xa0oz");
                // processPriceUpdate(manual, platinumChange, data.platinum.change);
                //
                // platinumChange.removeClass('positive negative').addClass(data.platinum.changeType);
            }
            if (data.hasOwnProperty('palladium')) {
                processPriceUpdate(manual, palladiumCurrent, roundValue(data.palladium.current), " /\xa0oz");
                // processPriceUpdate(manual, palladiumChange, data.palladium.change);
                //
                // palladiumChange.removeClass('positive negative').addClass(data.palladium.changeType);
            }

            livePriceTicker.removeClass('gbp usd eur jpy').addClass(currency);

            $('.currencies-title').removeClass('gbp usd eur jpy').addClass(currency);
        }
    });
}

export {getMetalPrices}