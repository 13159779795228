function showAlternateOption(metal) {
    if (metal != 'gold') {
        $('#gold-metal-account-slider').addClass('in');
    }
    if (metal != 'silver') {
        $('#silver-metal-account-slider').addClass('in');
    }
    if (metal != 'allocated_gold') {
        $('#allocated_gold-metal-account-slider').addClass('in');
    }
    if (metal != 'allocated_silver') {
        $('#allocated_silver-metal-account-slider').addClass('in');
    }

    if (metal.indexOf('allocated_') >= 0) {
        $('#digit_aul_saving').show();
    } else {
        $('#digit_aul_storage').show();
    }
}

export {showAlternateOption}