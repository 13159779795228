// This file should contain any javascript functions
/*eslint-disable */
import slider from 'bootstrap-slider';
import {updateDeliveryCountry} from "./functions/updateDeliveryCountry";
import {newUpdateDeliveryCountry} from "./functions/newUpdateDeliveryCountry";
/*eslint-enable */

// Lbma revert
//const customer_service_start = '09:00:00',
//customer_service_end = '16:00:00';

/*function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}*/

/*
function findCheckboxLevel(checkbox_name) {
    if (checkbox_name.substr(0,8) == "category")
        return checkbox_name.substr(8,1);
    else
        return 0;
}*/

function itemsAreCheckboxes(items) {
    if (items.length < 1)
        return false;

    var item = items[0];
    return $(item).val() != '';
}

/*function resetAllCheckboxes(form) {
    var checked = $(form).find('input:checked');
    checked.each(function(k, v) {
        $(this).prop('checked', false);
    });
}*/

function createUrlHash() {
    var selected_filters = $('.selected_hidden_filter'),
        selected_filters_hash = '#';
    $( selected_filters ).each(function() {
        var filter_type = $( this ).data('type'),
            filter = $( this ).data('filter'),
            filter_value = $( this ).val();
        if (selected_filters_hash !== '#') {
            selected_filters_hash = selected_filters_hash + "::";
        }
        selected_filters_hash = selected_filters_hash + filter_type + ":" + filter + ":" + filter_value;
    });
    selected_filters_hash = encodeURI(selected_filters_hash);
    return selected_filters_hash;
}

function addUrlHashToLinks(selected_filters_hash) {
    $( '.filter-section[id^=categories] ul a' ).each(function() {
        var href = $( this ).attr('href');
        $( this ).attr('href', href + selected_filters_hash);

    });
}

var StaticUpdateDeliveryCountry = function(data, type = 'checkout') {
    if (type == 'newcheckout') {
        newUpdateDeliveryCountry(data);
    } else {
        updateDeliveryCountry(data);
    }
}
// Lbma revert
//function withinTimePeriod(time) {
//if (time >= customer_service_start && time <= customer_service_end) {
//return true;
//}

//return false;
//}

export {addUrlHashToLinks, createUrlHash, itemsAreCheckboxes};
global.StaticUpdateDeliveryCountry = StaticUpdateDeliveryCountry;
