import {showPopup} from "../showPopup";
import {closePopup} from "../closePopup";
import {showAjaxPopup} from "../showAjaxPopup";

function request_callback_response(parent_id, previously_opened) {
    if (!previously_opened) {
        showPopup('.popup-ajax');
        closePopup('.popup-ajax');
        previously_opened = true;

        showAjaxPopup('request-callback', parent_id, null, null, true);
    }
}

export {request_callback_response}