// Update live metal prices
function processPriceUpdate(manual, selector, newValue, weight_string = '')
{
    // if manual update, no need to apply the flash
    if (manual) {
        selector.text(((newValue != null) ? newValue : '') + weight_string);
    }
    else {
        // check if the value has changed or not
        if (selector.text() != newValue) {
            // apply 100ms timeout so value updates at the same time as transition effect
            setTimeout(function() {
                selector.text(((newValue != null) ? newValue : '') + weight_string);
            }, 100);

            // add transition effect, then remove after 1000ms
            selector.parent().addClass('active').delay(1000).queue(function(next){
                $(this).removeClass('active');
                next();
            });
        }
    }
}

export {processPriceUpdate}