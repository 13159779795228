import {showPopup} from "../showPopup";

function add_to_basket_response(popup_contents) {
    if (typeof popup_contents.multiAdd != "undefined") {
        popup_contents.multiAdd();
    }

    if ($('#popup-add-to-basket .popup .content #add-to-basket-form').length) {
        //Hide cross on important notice popup
        if (popup_contents.result.related.indexOf("<h2>Important Notice</h2>") >= 0) {
            $('#popup-add-to-basket .close').addClass('hidden');
        }

        // populate related items
        $('#popup-add-to-basket .popup .content').html(popup_contents.result.related);

        if (popup_contents.overlay.find('img').length) {
            showPopup('.popup-ajax');

            var popup_lazyload_checker = setInterval(function() {
                if ($('.popup-ajax').hasClass('lazyloaded')) {
                    // add slick slider
                    $('#popup-add-to-basket .popup .content .scroller').slick({
                        dots: true,
                        speed: 300,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        arrows: false,
                        infinite: false,
                        mobileFirst: true,
                        responsive: [
                            {
                                breakpoint: 767,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 4
                                }
                            }
                        ]
                    });

                    clearInterval(popup_lazyload_checker);
                }
            }, 50);
        }
    }
}

export {add_to_basket_response}