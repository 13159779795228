var ToggleGroup = function() {

    var TOGGLE_GROUP_SELECTOR = ".toggle-group";
    var TOGGLE_CLASS = ".toggle-btn";
    var ACTIVE_CLASS = "active";
    var VIDEO_PLAY_CLASS = "play-video-toggle";
    var HAS_TARGET_CLASS = 'has-target';

    var element;

    this.init = function() {
        handleClick();
    };

    var handleClick = function() {
        $(document).on('click', TOGGLE_CLASS, function () {
            element = $(this);
            if(element.hasClass(ACTIVE_CLASS)){
                $(element).removeClass(ACTIVE_CLASS);
            }else{
                toggleGroup();
            }
        });
        $(document).on('click', '.toggle-metal-prices', function(){
            $('.live-prices').toggleClass('active');
        });
    };

    var toggleGroup = function() {
        element.closest(TOGGLE_GROUP_SELECTOR).find(TOGGLE_CLASS).removeClass(ACTIVE_CLASS);
        element.toggleClass(ACTIVE_CLASS);
        if($(element).hasClass(VIDEO_PLAY_CLASS)){
            //var video = element.closest(TOGGLE_GROUP_SELECTOR).find(VIDEO_DOM);
                $('video').get(0).play();
        }
        if($(element).hasClass(HAS_TARGET_CLASS)){
            var index = $(element).index();
            var parent = $(element).parents(TOGGLE_GROUP_SELECTOR);
            $(parent).find('.toggle-target').each(function(){
                $(this).removeClass(ACTIVE_CLASS);
                if($(this).index() == index){
                    $(this).addClass(ACTIVE_CLASS);
                }
            })
        }
    }

};

$(function(){
    var tg = new ToggleGroup();
    tg.init();
})
