function convertCheckoutCurrencyFromGBP(value) {
    var currency = $('.checkout-total .checkout-price').attr('data-currency'),
        currency_rate = $('.checkout-total .checkout-price').attr('data-currency_rate'),
        new_value;

    if (currency != 'gbp' &&
        typeof currency_rate != 'undefined' &&
        (typeof value != 'undefined' && value != '')) {

        new_value = parseFloat(value) / parseFloat(currency_rate);
        return new_value.toFixed(2);
    } else if (typeof value != 'undefined' && value != '') {
        return parseFloat(value).toFixed(2);
    }

    return value;
}

export {convertCheckoutCurrencyFromGBP}