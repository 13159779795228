function updateBasketPrice(elem, price) {
    // set up formatter
    var el = $(elem).find('.product-price'),
        currency = $('.checkout-total .product-price').attr('data-currency');

    if (currency.length < 1) {
        currency = $('#selected_currency').attr('title');
    }

    el.attr('data-price', price);
    $.ajax({
        url: '/checkout/currency-rate',
        type: 'post',
        data: {'currency': currency},
        dataType: 'json',
        success: function(rate) {
            var formatter = new Intl.NumberFormat('en-GB', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                signDisplay: 'auto'
            });
            el.text(formatter.format(price * rate));
        }
    });
}

export {updateBasketPrice}