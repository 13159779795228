var FullSize = function() {
    var SELECTOR = ".js-height",
        CUSTOM_HEIGHT_CLASS = "js-custom-height",
        RESPONSIVE_CUSTOM_HEIGHT_CLASS = "js-custom-height-responsive",
        REMOVE_HEADER_HEIGHT = "",
        height;
    this.init = function() {
        $(window).bind("load resize orientationchange", scaleAll);
    };
    var scaleAll = function() {
        height = $(window).height();
        $(SELECTOR).each(function() {
            if($(this).hasClass(REMOVE_HEADER_HEIGHT)){
                if($(window).width() >= 992){
                    height = height - 256;
                }else{
                    height = height - 63;
                }
            }
            if($(this).hasClass(CUSTOM_HEIGHT_CLASS)) {
                $(this).css("height", height + "px");
            } else if($(this).hasClass(RESPONSIVE_CUSTOM_HEIGHT_CLASS)) {
                var winWidth = $(window).width();
                var calcHeight;
                if(winWidth >= 1200){
                    calcHeight = (height / 100) * $(this).attr('data-lg-height');
                    $(this).css("height",calcHeight+"px");
                }else if(winWidth >= 992){
                    calcHeight = (height / 100) * $(this).attr('data-md-height');
                    $(this).css("height",calcHeight+"px");
                }else if(winWidth >= 768){
                    calcHeight = (height / 100) * $(this).attr('data-sm-height');
                    $(this).css("height",calcHeight+"px");
                }else{
                    calcHeight = (height / 100) * $(this).attr('data-xs-height');
                    $(this).css("height",calcHeight+"px");
                }
            } else if(shouldScale($(this)) ) {
                $(this).css("height", height + "px");
            }
        });
    };
    var shouldScale = function() {

        /*if(ele.attr("data-min-height") != undefined && ele.attr("data-min-height") > height) {
            var newHeight = parseInt(ele.attr("data-min-height"));
        }

        /*if(ele.hasClass(NOT_MOBILE_CLASS) && $(window).width() < 720) {
            ele.css("height", "auto");
            return false;
        }*/
        return true;
    };
};

$(function(){
    var fs = new FullSize();
    fs.init();
});