import {newUpdateBasketPrice} from "./newUpdateBasketPrice";

function newUpdatePaymentOwed(result) {
    var class_name = '.checkout-basket-payment-owed',
        el = $(class_name);

    if (el.length > 0) {
        if (result.total !== result.payment_owed) {
            el.show();
            newUpdateBasketPrice(class_name, result.payment_owed);
            $('.checkout-divider').removeClass('hidden');
        } else {
            el.hide();
            $('.checkout-divider').addClass('hidden');
        }
    }
}

export {newUpdatePaymentOwed}