import {newUpdateBasketPrice} from "./newUpdateBasketPrice";

function newUpdateBasketTotal(total) {
    newUpdateBasketPrice('.checkout-total', total);
    newUpdateBasketPrice('.checkout-basket-total', total);

    var allow_alternative_address = $('#allow-alternative-address').val();

    if (total >= 10000 && allow_alternative_address == 0) {
        if ($('#newcheckout-bacs_address').is(':checked')) {
            $('#newcheckout-bacs_address').trigger('click');
        }

        $('.bacs_address_toggle').addClass('hidden');
    } else if ($('#newcheckout-delivery_option input[type="radio"]:checked').val() != 'COLLECT' &&
        $('#newcheckout-delivery_option input[type="radio"]:checked').val() != 'STORAGE') {
        $('.bacs_address_toggle').removeClass('hidden');
    }
}

export {newUpdateBasketTotal}