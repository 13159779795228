function gaEvents()
{
    if(window.ga) {
        var user_id = $('#user_id').val();

        if (user_id && user_id !== undefined) {
            /*eslint-disable */
            ga('set', 'userId', user_id);
            ga('send', 'pageview');
            /*eslint-enable */
            //console.log("Sending pageview for user " + user_id);
        }

        $(document).on('click', '[ga-event=""], [data-ga-event]', function () {
            /*eslint-disable */
            var category = $.trim($(this).text());
            if ($(this).data('category') !== undefined) {
                category = $(this).data('category');
            }
            ga('send', 'event', {
                eventCategory: '\'' + category + '\'',
                eventAction: 'click',
                eventLabel: '\'' + $(this).data('label') + '\''
            });
            /*eslint-enable */
        });
    }
}

export {gaEvents}