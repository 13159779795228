function newUpdateBasketPrice(elem, price) {
    // set up formatter
    var el = $(elem).find('.checkout-price'),
        currency = $('.checkout-total .checkout-price').attr('data-currency'),
        currency_rate = $('.checkout-total .checkout-price').attr('data-currency_rate'),
        formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            signDisplay: 'auto'
        });

    el.attr('data-price', price);

    el.text(formatter.format(price / currency_rate));
}

export {newUpdateBasketPrice}