import {updateBasketPrice} from "./updateBasketPrice";
import {updateBasketTotal} from "./updateBasketTotal";

function updateDeliveryCountry(data) {
    var delay = 0;

    $('.checkout-main-step-1 > .overlay').fadeIn();

    $.ajax({
        url: '/checkout/update-delivery-country',
        type: 'post',
        data: data,
        dataType: 'json',
        success: function (result) {
            if (result.new_delivery_options) {
                delay = 500;
                updateBasketPrice('.checkout-delivery-total', result.delivery);
                $.ajax({
                    url: '/checkout/display-delivery-options',
                    type: 'post',
                    data: {
                        'delivery_options': result.delivery_options
                    },
                    dataType: 'json',
                    success: function (delivery_options) {
                        $('.field-checkout-delivery_option').html(delivery_options + '<div class="help-block"></div>');
                        $('html, body').animate({
                            scrollTop: $('.field-checkout-delivery_option').offset().top
                        });
                    }
                });
            }

            if (result.admin_fee == '0') {
                $('.checkout-basket-admin-fee').hide();
            } else {
                $('.checkout-basket-admin-fee').show();
            }
            updateBasketPrice('.checkout-basket-admin-fee', result.admin_fee);

            if (result.new_tax_state) {
                delay = 1000;
                updateBasketPrice('.checkout-subtotal', result.subtotal);
                updateBasketPrice('.checkout-vat', result.vat);
                updateBasketTotal(result.total);
                $.each(result.items, function (index, product) {
                    updateBasketPrice('.order-item[data-id="' + index + '"]', product.total_inc_vat);
                });
            }

            setTimeout(function() {
                $('.checkout-main-step-1 > .overlay').fadeOut();
            }, delay);
        }
    });
}

export {updateDeliveryCountry}