import {getCurrencySymbol} from "./getCurrencySymbol";

function formatCategoryFilterPrices(currency, rate) {
    var currency_symbol = getCurrencySymbol(currency),
        selected_price_bracket = $('.filter-section-selection-choice[data-filter="price_numeric"]'),
        price_filter = $('.filter-section#price_numeric'),
        price_bracket_labels = price_filter.find('ul a label'),
        price_range_input_button = price_filter.find('#price_input_button');

    if (currency !== 'gbp') {
        price_range_input_button.attr('data-price_conversion', rate);
    } else {
        price_range_input_button.removeAttr('data-price_conversion');
    }

    selected_price_bracket.each(function() {
        var gbp_price = $(this).data('gbp_price_bracket').toString(),
            no_hits_label_text,
            prices = gbp_price.match(/[\d.]+/g);

        if (prices != null) {
            var first_price = prices[0],
                second_price,
                new_first_price,
                new_second_price;

            if (currency === 'gbp') {
                new_first_price = currency_symbol + first_price;

                if (prices.length > 1) {
                    second_price = prices[1];
                    new_second_price = currency_symbol + second_price;
                }
            } else {
                new_first_price = currency_symbol + (first_price * rate).toFixed(2)

                if (prices.length > 1) {
                    second_price = prices[1];

                    new_second_price = currency_symbol + (second_price * rate).toFixed(2)
                }
            }

            if (prices.length > 1 && new_second_price != null) {
                no_hits_label_text = new_first_price.replace('.00', '') + ' - ' + new_second_price.replace('.00', '');
            } else {
                no_hits_label_text = gbp_price.replace(first_price, new_first_price.replace('.00', ''))
            }

            $(this).text('Price: ' + no_hits_label_text);
        }
    })

    price_bracket_labels.each(function () {
        var gbp_price = $(this).find('input').data('gbp_price_bracket');

        $(this).contents().filter(function() {
            return this.nodeType == Node.TEXT_NODE;
        }).each(function(){
            var label_text = $(this)[0].nodeValue,
                new_label_text,
                no_hits_label_text = gbp_price.toString(),
                hit_number = label_text.match(/[(][\d]+[)]/g),
                prices = no_hits_label_text.match(/[\d.]+/g);

            if (prices != null  && hit_number != null) {
                prices.forEach(function(price) {
                    var new_price;
                    if (currency === 'gbp') {
                        new_price = currency_symbol + price;
                    } else {
                        new_price = currency_symbol + (price * rate).toFixed(2)
                    }

                    no_hits_label_text = no_hits_label_text.replace(price, new_price);
                });

                new_label_text = no_hits_label_text + ' ' + hit_number;

                $(this)[0].nodeValue = new_label_text;
            }
        });
    })
}

export {formatCategoryFilterPrices};