import {newUpdateBasketPrice} from "./newUpdateBasketPrice";
import {newUpdateBasketTotal} from "./newUpdateBasketTotal";
import {clearCreditAndMetalAccountUsage} from "./clearCreditAndMetalAccountUsage";
import {loadCheckoutPrices} from "./loadCheckoutPrices";

function newUpdateDeliveryCountry(data) {
    $('.overlay').fadeIn();

    if ($('#newcheckout-bacs_address').is(':checked')) {
        data = {
            'billing_country_id': data.billing_country_id,
            'bacs_address': 'yes',
            'delivery_address_1': $('#newcheckout-delivery_address_1').val() || null,
            'delivery_address_2': $('#newcheckout-delivery_address_2').val() || null,
            'delivery_town': $('#newcheckout-delivery_town').val() || null,
            'delivery_country_id': data.delivery_country_id,
            'delivery_state_id': $('#newcheckout-delivery_state_id').val() || null,
            'delivery_postcode': $('#newcheckout-delivery_postcode').val() || null
        }
    } else {
        data = {
            'billing_country_id': data.billing_country_id,
            'bacs_address': 'no',
            'delivery_address_1': null,
            'delivery_address_2': null,
            'delivery_town': null,
            'delivery_country_id': data.delivery_country_id,
            'delivery_state_id': null,
            'delivery_postcode': null
        }
    }

    $.ajax({
        url: '/new-checkout/update-delivery-country',
        type: 'post',
        data: data,
        dataType: 'json',
        success: function (result) {
            if (result.basket.admin_fee == '0') {
                $('.checkout-basket-admin-fee').hide();
            } else {
                $('.checkout-basket-admin-fee').show().find('.payment_type').html(result.payment_option.name);
            }

            newUpdateBasketPrice('.checkout-basket-admin-fee', result.basket.admin_fee);

            if (result.basket.new_tax_state) {
                newUpdateBasketPrice('.checkout-subtotal', result.basket.subtotal);
                newUpdateBasketPrice('.checkout-vat', result.basket.vat);
                newUpdateBasketTotal(result.basket.total);
                $.each(result.basket.items, function (index, product) {
                    newUpdateBasketPrice('.order-item[data-id="' + index + '"]', product.total_inc_vat);
                });
            }
        }
    }).then(function(result) {
        if (result.basket.new_delivery_options) {
            newUpdateBasketPrice('.checkout-delivery-total', result.basket.delivery);

            $.ajax({
                url: '/new-checkout/display-delivery-options',
                type: 'post',
                data: {
                    'delivery_options': result.basket.delivery_options
                },
                dataType: 'json',
                success: function (delivery_options) {
                    $('.field-newcheckout-delivery_option').html(delivery_options + '<div class="help-block"></div>');

                    clearCreditAndMetalAccountUsage();
                    loadCheckoutPrices();

                    $('.overlay').fadeOut()
                }
            });
        } else {
            $('.overlay').fadeOut();
        }
    });
}

export {newUpdateDeliveryCountry}