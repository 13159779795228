import {getStickyHeaderHeight} from "./getStickyHeaderHeight";

const getOffset = (element, horizontal = false) => {
    if(!element) return 0;
    return getOffset(element.offsetParent, horizontal) + (horizontal ? element.offsetLeft : element.offsetTop);
}

function scrollToHashLocationWithStickyHeader() {
    var headerHeight = getStickyHeaderHeight(),
        element = document.getElementById(location.hash.slice(1));

    if (typeof element !== 'undefined' && element !== null) {
        $('html, body').animate({
            scrollTop: (getOffset(element) - element.offsetHeight - headerHeight - 20)
        }, 500);
    }
}

export {scrollToHashLocationWithStickyHeader}
