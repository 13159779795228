function getStickyHeaderHeight() {
    let headerHeight = 8;
    if ($(".blog_navigation_top").length > 0) {
        headerHeight += $(".blog_navigation_top").outerHeight(true);
    } else if ($('#live-price-ticker').length > 0 && !($('#live-price-ticker .minimiser').hasClass('closed'))) {
        headerHeight += $("#live-price-ticker").outerHeight(true);
    }

    return headerHeight;
}

export {getStickyHeaderHeight}