// More refactoring to do
// This file should contain any javascript required to run on all (or most) pages

import {scrollToHashLocationWithStickyHeader} from "./functions/scrollToHashLocationWithStickyHeader";
import {checkCurrency} from "./functions/checkCurrency";
import {gaEvents} from "./functions/gaEvents";
import {showPopup} from "./functions/showPopup";
import {showAjaxPopup} from "./functions/showAjaxPopup";

/*eslint-disable */
import appendAround from '@zeitiger/appendaround';
import {getCookie} from "./functions/getCookie";
import {setCookie} from "./functions/setCookie";
/*eslint-enable */

$(document).ready(function() {
    if (location.hash) {
        setTimeout(function() {
            window.scrollTo(0, 0);
        }, 1);

        var cms_content_lazyload_check = setInterval(function() {
            if (!$('#js-live-price-ticker .minimiser').hasClass('closed') &&
                $('#js-live-price-ticker').hasClass('lazyloaded')) {
                if (($('.cms-wrapper').length < 1 || $('.cms-wrapper').hasClass('lazyloaded')) &&
                    ($('.main').length < 1 || $('.main').hasClass('lazyloaded')) &&
                    ($('.cms-content').length < 1 || $('.cms-content').hasClass('lazyloaded'))) {

                    scrollToHashLocationWithStickyHeader();
                    clearInterval(cms_content_lazyload_check);
                }
            }
        }, 300);
    }

    checkCurrency();

// Load GA event tracking
    gaEvents();

// Allow contenteditable areas to have key functions
    $(document).on('focus', 'body [contenteditable]', function() {
        var $this = $(this);
        $this.data('before', $this.html());
        return $this;
    }).on('blur keyup paste input', ' body [contenteditable]', function() {
        var $this = $(this);
        if ($this.data('before') !== $this.html()) {
            $this.data('before', $this.html());
            $this.trigger('change');
        }
        return $this;
    });

    /*if (!getCookie("cookie_acceptance")) {
         // jQuery and jquery-colorbox are now loaded and ready to use
        $('#popup-cookie').addClass('active');
        $('#js-cookie-accept').click(function () {
            $('#popup-cookie').removeClass('active');
            setCookie("cookie_acceptance", 1);
        });
    }*/

    /*Defines navbar hover events
    $(document).ready(function() {
        function toggleNavbarMethod() {
            if ($(window).width() > 768) {
                $('.nav-primary .nav-full > li').on('mouseover', function(){
                    $('.sub', this).trigger('click');
                }).on('mouseout', function(){
                    $('.sub', this).trigger('click').blur();
                });
            }
            else {
                $('.nav-primary .nav-full > li').off('mouseover').off('mouseout');
            }
        }

        // toggle navbar hover
        toggleNavbarMethod();

        // bind resize event
        $(window).resize(toggleNavbarMethod);
    });

    $('ul.nav-primary li.nav-full').hover(function() {
        $(this).find('.sub').stop(true, true).delay(200).fadeIn(500);
    }, function() {
        $(this).find('.sub').stop(true, true).delay(200).fadeOut(500);
    });*/

// Click investment product info icon
// $('.product-snippets .info').click(function() {
//     // desktop selector class
//     var tab = $('.tab-titles .tab-terms-of-sale');
//
//     // if desktop tabs are hidden
//     if($('.tab-titles').is(':hidden')) {
//         // mobile selector class
//         tab = $('.tab-accordian.tab-terms-of-sale');
//     }
//
//     tab.click();
// });

    // Product enquiry button
    $(document).on('click', '.show-product-enquiry-popup', function() {
        showAjaxPopup('product-enquiry');
    });

// contact us
    $(document).on('click', '.btn-contact-us', function() {
        var button = $(this);
        var redirect = null;

        if (button.parents('.contact-us-link').length) {
            redirect = button.parents('.contact-us-link').attr('href');
            window.location.href = redirect;
        }
        event.preventDefault();
    });

// Newsletter scroll row on homepage
    $(document).on('click', '#newsletter-scroll', function() {
        $('html, body').animate({ scrollTop: $('.newsletter-sign-up').offset().top }, 500);
    });

// Search form on header
    /*$('.search-form').submit(function() {
        if ($('#page-category').length) {
            var val = $(this).children('.keywords').val();

            $('#product-search').val(val).change();

            $(this).children('.keywords').blur();

            return false;
        }
    });*/

    /**
     * Click a popup trigger button
     */
    $(document).on('click', '.btn-popup', function() {
        var btn = $(this);
        var target = btn.data('target');
        var login_required = btn.data('login-required');
        var parent_id = btn.data('parent-id');

        if (login_required == 1) {
            showPopup('#popup-login-register');
            $('#popup-login-register').data('origin', target);
        }
        else {
            showPopup(target);
        }

        // set the parent id
        $(target).find('.parent-id-input').val(parent_id);

        // set the form action for the add to basket form
        $(target).find('#add-to-basket-form').attr('action', '/basket/add/' + parent_id);
    });

    /**
     * Click an ajax popup trigger button
     */
    $(document).on('click', '.btn-ajax-popup', function() {
        var btn = $(this);
        var target = btn.data('target');
        var target_popup = "#popup-" + target;
        var login_required = btn.data('login-required');
        var parent_id = btn.data('parent-id');

        if (login_required == 1) {
            showAjaxPopup('login-register', parent_id, target);
        } else {
            showAjaxPopup(target, parent_id);
        }

        // set the parent id
        $(target_popup).find('.parent-id-input').val(parent_id);

        // set the form action for the add to basket form
        $(target_popup).find('#add-to-basket-form').attr('action', '/basket/add/' + parent_id);
    });

    /**
     * Click an iframe popup button
     */
    $(document).on('click', '.btn-iframe-popup', function() {
        var btn = $(this);
        var url = btn.data('url');
        var popup_id = btn.data('popup-id');

        $('.popup-iframe').find('.content').html('<iframe src="' + url + '" />');
        $('.popup-iframe').attr('id', popup_id);
        showPopup('.popup-iframe');
        return false;

    });

    if ($('#complete-registration-form').length === 0) {
        setTimeout(function () {
            //only show newsletter prefs if logged in and popup hasn't been closed in last 30 days
            if (
                $('.login-btn-click a').attr('href') == "/account/dashboard" &&
                !getCookie('newsletter-prefs-closed') &&
                $('#popup-newsletter-prefs').length === 0
            ) {
                showAjaxPopup('newsletter-prefs');
            }
        }, 10000);
    }

     if (getCookie('_identity')) {
        $('#js-sign-in-text').text('Account');
    }

}); // end document ready

$(function () {
    $('.team-card').click(function(){
        var teamCard = $(this);
        teamCard.toggleClass('team-card-clicked');
    });

    $(document).ready(function () {
        $('#sale-1').change(function () {
            $('#sale-1').closest("form").submit();
        });

        $('#sale-2').change(function () {
            $('#sale-2').closest("form").submit();
        });

        $('#sale-3').change(function () {
            $('#sale-3').closest("form").submit();
        });
    });

    $(document).on('click', '#check-metal-prices', function(event) {
        event.preventDefault();
        var btn = $(this),
            link = btn.attr('data-link');

        $('html, body').animate({
            scrollTop: $("#" + link).offset().top
        }, 500);
    });

    $(document).on('click', "a[href*='#']:not([href='#'])", function(e) {
        e.preventDefault();
        location.hash = $(this).attr('href');
        scrollToHashLocationWithStickyHeader();
    });
});