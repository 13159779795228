import {getCurrencySymbol} from "./getCurrencySymbol";

function setCurrency(currency) {
    var currency_symbol = getCurrencySymbol(currency);
    $('#live-price-ticker').removeClass('gbp usd eur jpy').addClass(currency);
    $('#selected_currency').attr('title', currency).text(currency_symbol);

    var buttons = $('.currencies li button[data-type="'+ currency +'"]');

    var button_parents = buttons.parent();

    button_parents.addClass('active');
    button_parents.siblings().removeClass('active');
}

export {setCurrency}