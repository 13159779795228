function notice_response(popup_contents) {
    if (popup_contents.result.status == 'exists') {
        $('#popup-notice').find('.popup h2').text('Existing Subscription');
        $('#popup-notice').find('.popup .info').text(popup_contents.result.message);
    } else {
        $('#popup-notice').find('.popup h2').text('Error');
        $('#popup-notice').find('.popup .info').text(popup_contents.result.message);
    }
}

export {notice_response}