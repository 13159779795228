function getCurrencySymbol(currency)
{
    switch (currency) {
        case 'gbp':
            return '£';
        case 'usd':
            return '$';
        case 'eur':
            return '€';
        case 'jpy':
            return '¥';
        default:
            return '£';
    }
}

export {getCurrencySymbol}