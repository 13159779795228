import {showPopup} from "./showPopup";
import {AjaxPopupFormSubmissionCases} from "./AjaxPopupFormSubmissionCases";

// Show an ajax popup
function showAjaxPopup(target, parent_id = null, origin = null, popup_contents = null, previously_opened = false) {
    if (previously_opened) {
        $('.popup-ajax').addClass('active');
    } else {
        $('.popup-ajax').
        removeClass('lazyloaded').
        addClass('lazyload').
        removeAttr('id').
        removeAttr('data-link').
        removeAttr('data-script');
    }

    $('.popup-ajax').find('.content').html('');

    $.ajax({
        url: '/popup/' + target + (parent_id == null || typeof parent_id == "undefined" ? '' : '/' + parent_id),
        type: 'get',
        dataType: 'json'
    }).done(function (result) {
        if (result.status == false) {
            alert('Sorry, there was an error processing your request.');
        } else {
            const wp = $('.popup-ajax').data('wp');
            const popup_id = 'popup-' + target

            $('.popup-ajax').
            attr('id', popup_id).
            attr('data-link', wp + '/css/' + popup_id + '.css').
            attr('data-script', wp + '/js/' + popup_id + '.js');

            if (target == 'request-callback') {
                $('.popup-ajax .close').siblings().addClass('content').removeClass('display-table').html('');
            }

            $('.popup-ajax').find('.content').html(result.view);

            if (result.popup_needed == true) {
                AjaxPopupFormSubmissionCases($('.popup-ajax'), popup_contents, parent_id, previously_opened);

                if (target !== 'add-to-basket') {
                    showPopup('.popup-ajax');
                }
            } else {
                if (origin !== null) {
                    showAjaxPopup(origin, parent_id);
                } else {
                    alert('This action has already been performed');
                }
            }
        }
    }).fail(function () {
        alert('Sorry, there was an error processing your request.');
    });
}

global.showAjaxPopup = showAjaxPopup;

export {showAjaxPopup}