import {newUpdateBasketPrice} from "./newUpdateBasketPrice";
import {newUpdatePaymentOwed} from "./newUpdatePaymentOwed";
import {newUpdateBasketTotal} from "./newUpdateBasketTotal";
import {loadCheckoutPrices} from "./loadCheckoutPrices";

function newUpdateCreditUsage(overlay= true) {
    var value = 0,
        currency = $('.checkout-total .checkout-price').attr('data-currency'),
        currency_rate = $('.checkout-total .checkout-price').attr('data-currency_rate');

    if (typeof $('#newcheckout-credit_usage').val() != 'undefined' &&
        $('#newcheckout-credit_usage').val() != '') {
        value = parseFloat($('#newcheckout-credit_usage').val());
    }

    if (currency != 'gbp' && typeof currency_rate != 'undefined') {
        value = value * parseFloat(currency_rate);
    }

    var data = {
        'value': value
    };

    if (overlay) {
        $('.overlay').fadeIn();
    }

    $.ajax({
        url: '/new-checkout/update-credit-usage',
        type: 'post',
        data: data,
        dataType: 'json',
        async: false,
        success: function (result) {
            if (result.credit_usage == '0') {
                $('.checkout-basket-pre-payment-usage').hide();
            } else {
                $('.checkout-basket-pre-payment-usage').show();
            }

            newUpdateBasketPrice('.checkout-basket-pre-payment-usage', -Math.abs(result.credit_usage));
            newUpdatePaymentOwed(result);
            newUpdateBasketTotal(result.total);

            loadCheckoutPrices();

            if (overlay) {
                setTimeout(function () {
                    $('.overlay').fadeOut();
                }, 800);
            }
        }
    });
}

export {newUpdateCreditUsage}