import {newUpdateCreditUsage} from "./newUpdateCreditUsage";
import {newUpdateMetalMonetaryUsage} from "./newUpdateMetalMonetaryUsage";

function clearCreditAndMetalAccountUsage() {
    $('.checkout-main-step-1 .metal-input-form .metal-value').each(function() {
        var metal = $(this).attr('data-metal'),
            answer = $('.checkout-form-answer-' + metal + '_usage');

        answer.removeClass('checkout-price').
        attr('data-price', false).
        html('').
        closest('.checkout-answer-container').
        css('display', 'none');

        if ($(this).val() != '') {
            $(this).val('');
            newUpdateMetalMonetaryUsage($(this), false);
        }
    })

    $('#metal_account-panel-answers').addClass('empty').collapse('hide');
    $('#metal_account-panel-heading .icon').removeClass('done');

    $('#newcheckout-credit_usage').val('');

    $('.checkout-form-answer-credit_usage').
    removeClass('checkout-price').
    attr('data-price', false).
    html('').
    closest('.checkout-answer-container').
    css('display', 'none');

    $('#pre_payment-panel-answers').addClass('empty').collapse('hide');
    $('#pre_payment-panel-heading .icon').removeClass('done');

    newUpdateCreditUsage(false);
}

export {clearCreditAndMetalAccountUsage}