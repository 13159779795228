import {getDefaultCurrency} from "./getDefaultCurrency";
import {getMetalPrices} from "./getMetalPrices";
import {setCurrency} from "./setCurrency";

function checkCurrency() {
    var default_currency = getDefaultCurrency();

    if (default_currency) {
        setCurrency(default_currency);
        getMetalPrices(true, default_currency);
    }
}

global.checkCurrency = checkCurrency;

export {checkCurrency}