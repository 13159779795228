import {login_register_response} from "./ajax_popup_responses/login_register_response";
import {confirm_response} from "./ajax_popup_responses/confirm_response";
import {add_to_basket_response} from "./ajax_popup_responses/add_to_basket_response";
import {pre_payment_topup_response} from "./ajax_popup_responses/pre_payment_topup_response";
import {notice_response} from "./ajax_popup_responses/notice_response";
import {newsletter_signup_response} from "./ajax_popup_responses/newsletter_signup_response";
import {request_callback_response} from "./ajax_popup_responses/request_callback_response";
import {sell_back_to_chards_response} from "./ajax_popup_responses/sell_back_to_chards_response";

function AjaxPopupFormSubmissionCases(target, popup_contents, parent_id, previously_opened) {
    switch (true) {
        case target.attr('id') == 'popup-login-register':
            return login_register_response(target, popup_contents);
        case target.attr('id') == 'popup-confirm':
            return confirm_response(popup_contents);
        case target.attr('id') == 'popup-add-to-basket':
            return add_to_basket_response(popup_contents);
        case target.attr('id') == 'popup-pre-payment-topup':
            return pre_payment_topup_response(popup_contents);
        case target.attr('id') == 'popup-notice':
            return notice_response(popup_contents);
        case target.attr('id') == 'popup-newsletter-signup':
            return newsletter_signup_response(popup_contents);
        case target.attr('id') == 'popup-request-callback':
            return request_callback_response(parent_id, previously_opened);
        case target.attr('id') == 'popup-sell-back-to-chards':
            return sell_back_to_chards_response(popup_contents);
        default:
            return null;
    }
}

export {AjaxPopupFormSubmissionCases}